
  .setCardWidth {
    width: 50%;
  }
  .signInCard
  {
      margin-left: 500px;
  }

  .cardImageHeight
  {
    height: 270px;
  }
  .login, .register{
    background-image: url("./img.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
    /* height: 1000px; */
  }
  .login{
    height: 700px;
  }
  .register{
    height: 1070px;
  }

  .color_white, .nameInput, .space-y-10 input{
    color: white;
  }
  .right_part {
    padding-top: 100px;
  }
  .grey.darken-4 {
    background-color: #212121 !important;
}
.reportCard{
  min-height: 800px;
}
  