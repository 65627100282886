.reportCard
{
    min-height: 400px;
    margin-right: 30px;
}

.textAreaHeight
{
    min-height: 270px;
    border: 1px solid black;
}

.cardTitle
{
    padding: 2px;
    margin-top: 10px;
    color: white;
    background-color: black;
}

.cardTitle2
{
    padding: 2px;
    margin-top: 10px;
    color: white;
    background-color: black;
}
.wal-address{
    margin: 0px;
    background: black;
    color: white;
    text-align: right;
    padding-right: 50px;
}
.bg-black {
    background: black;
    color: white;
}
.left-menu{
    padding: 0px 20px !important;
}
.left-menu ul li a{
    display: block;
    padding: 10px;
    border: 1px solid #7a7979;
    border-bottom: 0px;
    font-size: 18px;
    padding-left: 18px;
    color: #ececec;
    transition:all 0.5s ease-in-out;
    border-left: 0px;
    border-right: 0px;
}
.left-menu ul li a:hover{
    background-color: #053d79;
    padding-left: 50px;
}
.left-menu ul li:last-child a{
    border-bottom: 1px solid #7a7979;
}
.left-menu ul li a span{
    top: -9px;
    position: relative;
}
/***************************/

.left-menu{
    padding: 0px !important;
}
th, td{
    color: black;
}